
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';

export default defineComponent({
  name: 'SegmentSelection',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();

    this.$store.dispatch('toggleProgressBar', false);
    this.$store.dispatch('toggleTopBar', false);
    this.$store.dispatch('toggleSideBar', false);
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* HIDE SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleSideBar', false);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SegmentDataHelper.prepareComponentData(
        this.$store,
        callback,
        (e: Error) => {
          this.goToPreviousScreen(this, {});
        },
      );
    },
    goToNextStep(segment: string) {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      this.setStepAsCompleted(this, true);

      /* MOVE TO NEXT SCREEN */
      this.$router.push({
        name: 'Home',
        params: {
          segment,
        },
      });
    },
    goToCustomerPanel() {
      this.$store.dispatch('setAfterLogin', {
        name: 'CustomerPanel',
      });

      this.$router.push({
        name: 'CustomerPanel',
      });
    },
    segmentExists(segment: string): boolean {
      let found = false as boolean;

      Object.values(this.$store.state.segments).forEach((row: any) => {
        if (row.pid === segment) found = true;
      });

      return found;
    },
  },
});
